import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import CartContent from '../components/Cart/CartContent'
import { getDiscounts } from '../api/service'
import { navigate } from 'gatsby'

const Cart = () => {
    const [services, setServices] = useState(null)
    const [groupDiscounts, setGroupDiscounts] = useState(null)

    useEffect(() => {
        (async () => {
            try {
                if (!localStorage.getItem('user')) {
                    navigate('/signin')
                } 
                var cart = JSON.parse(localStorage.getItem('cart'))
                const data = await getDiscounts(cart)
                setGroupDiscounts(data.discounts)
                setServices(data.services)

            } catch (error) {
                navigate('/')
            }
        })()
    }, [])

    if (!services) return null

    return (
        <Layout title='Cesta'>
            <PageBanner
                pageTitle='Cesta'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Cesta'
            />
            <CartContent
                services={services}
                discounts={groupDiscounts}
            />
        </Layout>
    )
}

export default Cart